import ApplicationController from '../support/application_controller';
import { InputValidator } from '../helper-classes/input_validator';

export default class extends ApplicationController {
  static targets = [
    'input',
    'toggleable',
    'hideInput',
    'postcode',
    'postcodeInput',
  ];

  get currentValue() {
    if (this.hasPostcodeInputTarget) this.validatePostcodeInput();
    return this.hasInputTarget ? this.inputTarget.value : null;
  }

  get shouldShow() {
    return this.getValue('showValue')
      ? this.currentValue === this.getValue('showValue')
      : this.currentValue !== this.getValue('hideValue');
  }

  get shouldHide() {
    return this.getValue('hideValue')
      ? this.currentValue === this.getValue('hideValue')
      : this.currentValue !== this.getValue('showValue');
  }

  connect() {
    this.doToggle();
    if (this.hasPostcodeTarget) this.togglePostcode();
    if (this.hasHideInputTarget) this.hideAll();
  }

  validatePostcodeInput() {
    const postCodeLabel = this.postcodeTarget.querySelector('.js-input-container label');

    if (this.inputTarget.value === 'UK and Northern Ireland') {
      this.postcodeInputTarget.setAttribute('required', 'required');
      this.postcodeInputTarget.classList.remove('optional');
      this.postcodeInputTarget.classList.add('required');

      postCodeLabel.textContent = 'Postcode';
    } else {
      this.postcodeInputTarget.removeAttribute('required', 'required');
      this.postcodeInputTarget.classList.add('optional');
      this.postcodeInputTarget.classList.remove('required');

      postCodeLabel.textContent = 'Postcode (optional)';
    }

    InputValidator.validateRequired(this.postcodeInputTarget);
    InputValidator.validatePattern(this.postcodeInputTarget);
  }

  getValue(value) {
    return this.data.has(value) ? this.data.get(value) : null;
  }

  doToggle() {
    function defaultCountryValue() {
      const inputElement = document.getElementById("ucd_details_country_of_birth_label");
      if (inputElement) {
        return inputElement.value
      }
    }
    /**
     * @TODO: Extract the country logic to it's own method
     * @type {boolean}
     */
    function selectYesRadioIfNoNotChecked() {
      const radios = document.querySelectorAll('input[type="radio"]');
      let noRadioChecked = false;

      radios.forEach(radio => {
        const label = document.querySelector(`label[for="${radio.id}"]`);

        if (label && label.textContent.trim().startsWith("No") && radio.checked) {
          noRadioChecked = true;
        }
      });

      if (!noRadioChecked) {
        radios.forEach(radio => {
          const label = document.querySelector(`label[for="${radio.id}"]`);

          if (label && label.textContent.trim().startsWith("Yes") && !radio.checked) {
            radio.checked = true;
          }
        });
      }
    }
    function showNoNotes() {
      document.querySelectorAll('.no__notes.english_prof').forEach(el => el.style.display = 'block');
    }

    function hideNoNotes() {
      document.querySelectorAll('.no__notes.english_prof').forEach(el => el.style.display = 'none');
    }

    function toggleRadioButtonsBasedOnDefaultCountry() {
      if (["Ireland", "UK and Northern Ireland", ""].includes(defaultCountryValue())) {
        document.querySelectorAll('div.english_prof_radio_buttons').forEach(el => el.style.display = 'none');
        document.querySelectorAll('p.english_prof_employed_through_english').forEach(el => el.style.display = 'none');
      } else {
        document.querySelectorAll('div.english_prof_radio_buttons').forEach(el => el.style.display = 'block');
        document.querySelectorAll('p.english_prof_employed_through_english').forEach(el => el.style.display = 'block');
      }
    }

    const showRadioButtons = ["Ireland", "UK and Northern Ireland", ""].includes(this.currentValue)

    if (!showRadioButtons) {
      selectYesRadioIfNoNotChecked();
      document.querySelectorAll('p.english_prof_employed_through_english').forEach(el => el.style.display = 'block');
      // Display the radio buttons
      document.querySelectorAll('div.english_prof_radio_buttons').forEach(el => el.style.display = 'block');
      document.querySelectorAll('input[type="radio"]').forEach(radio => {
        const label = document.querySelector(`label[for="${radio.id}"]`);

        if (label) {
          radio.addEventListener('click', function () {
            if (label.textContent.trim() === 'Yes' || label.textContent.trim().startsWith('Yes')) {
              document.querySelectorAll('.yes-note').forEach(note => {
                note.style.display = 'block';
              });
              hideNoNotes();
            } else if (label.textContent.trim() === 'No') {
              document.querySelectorAll('.yes-note').forEach(note => {
                note.style.display = 'none';
              });
              showNoNotes();
            }
          });
        }
      });
    } else {
      document.querySelectorAll('div.english_prof_radio_buttons').forEach(el => el.style.display = 'none');
      document.querySelectorAll('p.english_prof_employed_through_english').forEach(el => el.style.display = 'none');
    }
    if (this.currentValue !== "") {
      toggleRadioButtonsBasedOnDefaultCountry();
    }
    if (this.shouldShow) {
      this.toggleableTarget.classList.remove('display-none');
    } else {
      this.toggleableTarget.classList.add('display-none');
    }
  }

  hideAll() {
    if (this.shouldHide) {
      this.hideInputTarget.classList.add('display-none');
    } else {
      this.hideInputTarget.classList.remove('display-none');
    }
  }

  togglePostcode() {
    if (this.inputTarget.value !== '') {
      this.postcodeTarget.classList.remove('display-none');
    } else {
      this.postcodeTarget.classList.add('display-none');
    }
  }
}
